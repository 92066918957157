body {
  background: var(--Maincolor-Background);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  overscroll-behavior: none;
  --Background-Credit: #1a1a1a;
  --Maincolor-Background: #3d3d3d;
  --Maincolor-contrast: #00ffdd;
  --Maincolor-contrast-lowlight: #00ffddcf;
  --PureBlack: #0e0e0e;
  --Aluminium: #d6d6d6;
  --Graphite: #1f1f27;
  --test: #339e02;
  --ds-background-brand-bold: var(--Maincolor-contrast-lowlight);
  --ds-background-brand-bold-hovered: var(--Maincolor-contrast);
  --ds-background-brand-bold-pressed: var(--Maincolor-contrast-lowlight);
  --ds-text: #fff;
  --ds-text-inverse: #333;
  --ds-surface-overlay: var(--Graphite);
  --ds-blanket: #33333380;
  --ds-background-selected: #3d3d3d;
  --ds-background-selected-hovered: #3d3d3d;
  --ds-background-selected-pressed: #3d3d3d;
  --ds-background-neutral-pressed: #3d3d3d;
  --ds-background-neutral-subtle-hovered: #555;
  --ds-background-neutral-subtle-pressed: #555;
  --ds-border-focused: var(--Aluminium);
  --ds-background-input: #22272b;
  --ds-background-input-hovered: var(--Graphite);
  --ds-background-input-focused: var(--Graphite);
  --ds-background-input-pressed: var(--Graphite);
  --ds-text-selected: var(--Maincolor-contrast-lowlight);
  --ds-border-selected: var(--Maincolor-contrast-lowlight);
  --ds-background-disabled: var(--ds-blanket);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table-wrapper {
  padding: 10px 20px;
}

.responsiveTable thead tr {
  background-color: var(--Aluminium);
  height: 60px;
}

.responsiveTable {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
}

.responsiveTable tbody tr {
  margin-top: 10px;
}

.responsiveTable th,
.responsiveTable td {
  border: solid 1px #000;
  border-style: solid none;
  padding: 10px;
  font-size: 14px;
}
.responsiveTable th {
  font-size: 12px;
}

.responsiveTable th:first-child,
.responsiveTable td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.responsiveTable th:last-child,
.responsiveTable td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.row-ai-center {
  display: flex;
  align-items: center;
}

.rs-table-td-text {
  word-break: break-all;
  min-width: 60px;
}

html,
body {
  height: 100% !important;
  margin: 0;
  background-color: var(--Background-Credit) !important;
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@media (max-width: 640px) {
  .rs-table-td-text {
    max-width: calc(50vw - 50px) !important;
    margin: 0;
  }
}

html, body {
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  overflow: auto !important;
}

.simplebar-track {
  background: transparent !important;
}

@media (max-width: 768px) {
  .simplebar-track {
    background: transparent !important;
  }
  .simplebar-scrollbar:before {
    background-color: #4a4a4a !important;
    border-radius: 10px !important;
    min-height: 78px !important;
  }
}

.simplebar-scrollbar:before {
  background-color: #4a4a4a  !important;
  border-radius: 10px !important;
  width: 8px !important;
}

.simplebar-scrollbar {
  min-height: 78px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #4a4a4a !important;
  border-radius: 10px;
  height: 78px;
}

::-webkit-scrollbar-button {
  display: none; 
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset !important; /* Fondo transparente */
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset !important; /* Fondo transparente */
  background-color: transparent !important; /* Fondo transparente */
  transition: background-color 5000s ease-in-out, color 5000s ease-in-out !important; /* Suaviza cambios de estilo */
  color: inherit !important; /* Color del texto */
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset !important; /* Fondo transparente */
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset !important; /* Fondo transparente */
  background-color: transparent !important; /* Fondo transparente */
  color: inherit !important; /* Color del texto */
}